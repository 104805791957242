import { gql } from "graphql-request";

export const CHAMP_REQUESTS_STATS = gql`
        query {
          newRequests: ChampsCount(where: { approved: null }) {
            count
          }
          approvedRequests: ChampsCount(
            where: { approved: 1, payment_time: { ne: null } }
          ) {
            count
          }
          deniedRequests: ChampsCount(where: { approved: 0 }) {
            count
          }
        }
`;

export const TEAM_MATCHES_STATS = gql`
  query ($id: Int, $date: String) {
    played: MatchesCount(
      where: {
        or: { team1_id: $id, team2_id: $id }
        finish_time: { lt: $date }
      }
    ) {
      count
    }
    win: MatchesCount(
      where: {
        or: { team1_id: $id, team2_id: $id }
        finish_time: { lt: $date }
        winner_id: $id
      }
    ) {
      count
    }
    loss: MatchesCount(
      where: {
        or: { team1_id: $id, team2_id: $id }
        finish_time: { lt: $date }
        winner_id: { ne: $id }
      }
    ) {
      count
    }
    draw: MatchesCount(
      where: {
        or: { team1_id: $id, team2_id: $id }
        finish_time: { lt: $date }
        winner_id: null
      }
    ) {
      count
    }
  }
`;

export const TEAM_GOALS = gql`
  query ($team_id: Int) {
    goals: Matches(where: { or: { team1_id: $team_id, team2_id: $team_id } }) {
      scored: match_events(
        where: { team_id: $team_id, match_event_type_id: 14 }
      ) {
        id
      }
      conceded: match_events(
        where: { team_id: { ne: $team_id }, match_event_type_id: 14 }
      ) {
        id
      }
    }
  }
`;

export const TEAM_CARDS = gql`
  query ($team_id: Int) {
    cards: Matches(where: { or: { team1_id: $team_id, team2_id: $team_id } }) {
      yellow_cards: match_events(
        where: { team_id: $team_id, match_event_type_id: 12 }
      ) {
        id
      }
      red_cards: match_events(
        where: { team_id: $team_id, match_event_type_id: 13 }
      ) {
        id
      }
    }
  }
`;

export const TEAM_WINNED_CHAMPS = gql`
  query ($team_id: Int) {
    champs: ChampsCount(
      where: { finish_time: { ne: null }, winner_id: $team_id }
    ) {
      count
    }
  }
`;

export const CHAMP_STATS = gql`
  query ($id: Int) {
    champStats(id: $id) {
      total_cards
      total_yellow_cards
      total_red_cards
      total_matches
      total_goals
      teams_total_cards {
        name
        logo
        total_cards
      }
      teams_goals {
        name
        logo
        goals
      }
      top_scorers {
        name
        img
        goals
      }
      top_assistants {
        name
        img
        assists
      }
    }
  }
`;

export const APP_STATS = gql`
  query {
    ongoing: ChampsCount(where: { actual_start_time: { ne: null } }) {
      count
    },
    upcoming: ChampsCount(
      where: { actual_start_time: null, payment_time: { ne: null } }
    ) {
      count
    },
    users:UsersCount(
      where: { user_role_id: { ne: 2 }, and: { user_role_id: { ne: 5 } } }
    ) {
      count
    },
  }
`;

export const SESSIONS_STATS = gql`
  query ($start_date: String, $end_date: String) {
    onlineUsers: SessionsCount(
      where: {
        finish_time: null
        token: { ne: null }
        start_time: { gt: $start_date, lt: $end_date }
      }
    ) {
      count
    }
    onlineGuests: SessionsCount(
      where: {
        finish_time: null
        token: null
        start_time: { gt: $start_date, lt: $end_date }
      }
    ) {
      count
    }
  }
`;

export const CHAMPSCOUNT_BETWEEN_TWO_DATES = gql`
query($start_date:String , $end_date: String){
  champsCount_between_two_dates(start_date:$start_date,end_date:$end_date){
		allChamps{
      month,
      number
    }
    rejectedChamps{
      month
      number
    }
  }
}
`;

export const PROFITS_BETWEEN_TWO_DATES = gql`
query($start_date:String , $end_date: String){
  amount:profits_between_two_dates(start_date:$start_date,end_date:$end_date){
    profit
    pending
  }
}
`;
